export const ProjectComplete = [
    {
        label: "Devam Eden",
        value: 1
    },
    {
        label: "Tamamlanan",
        value: 2
    }
]

export const ProjectCompleteEN = [
    {
        label: "Ongoing",
        value: 1
    },
    {
        label: "Completed",
        value: 2
    }
]

export const monthsTR = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
];

export const monthsEN = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const urlById = (url) => {
    const urlList = { "who-we-are": 2, "biz-kimiz": 2, "kisisel-verilerin-korunmasi": 25, "personal-data-protection": 25, "who-we-are#enler": 2, "biz-kimiz#enler": 2, "content/stakeholder-engagement": 11, "icerik/paydas-katilimi": 11, "who-we-are#mapa-group-about": 2, "biz-kimiz#mapa-grup-hakkinda": 2, "who-we-are#redefining-boundaries": 2, "biz-kimiz/sinirlari-yeniden-tanimlamak": 2, "who-we-are#our-affiliates": 2, "biz-kimiz#istiraklerimiz": 2, "sustainability": 3, "surdurulebilirlik": 3, "sustainability#our-strategy": 3, "surdurulebilirlik#stratejimiz": 3, "stakeholder-engagement": 11, "paydas-katilimi": 11, "environment": 12, "cevre": 12, "social": 13, "sosyal": 13, "governance": 14, "yonetisim": 14, "ungc-participant": 15, "ungc-katilimcisi": 15, "projects": 4, "projeler": 4, "kariyer": 5, "career": 5, "career#human-resources": 5, "kariyer#insan-kaynaklari": 5, "career#career-at-mapa": 5, "kariyer#mapada-kariyer": 5, "career#talent-acquisition-policy": 5, "kariyer#yetenek-kazanimi-politikasi": 5, "career#continuous-learning-and-development": 5, "kariyer#surekli-ogrenme-ve-gelisim": 5, "job": 22, "is-ilanlari": 22, "contact": 18, "iletisim": 18, "politikalarimiz-ve-standartlarimiz": 34, "our-policies-and-standards": 34, "kalite-standartlari": 35, "quality-standards": 35 }
    return urlList[url]
}

export const HomeAboutList = [
    {
        TR: "Toplam değeri 18,1 milyar doları aşan, 200'den fazla projeden oluşan kapsamlı bir portföy",
        EN: "An extensive portfolio comprising over 200 projects with a total value exceeding $18.1 billion"
    },
    {
        TR: "40 yılı aşkın bir deneyime sahip çok uluslu bir işletme",
        EN: "A multinational enterprise boasting a rich legacy spanning more than 40 years"
    },
    {
        TR: "Avrupa, Orta Doğu ve Afrika bölgelerinde uluslararası altyapı ve üstyapı projelerinin yürütülmesinde kanıtlanmış uzmanlık",
        EN: "Demonstrated expertise in executing international infrastructure and superstructure projects across Europe, the Middle East, and Africa"
    },
    {
        TR: "Otoyollar, demiryolları, boru hatları, barajlar ve hidroelektrik santralleri (HES), arıtma tesisleri, endüstriyel kompleksler, ticari binalar ve otel geliştirmeleri dahil olmak üzere kapsamlı bir hizmet yelpazesi sunma",
        EN: "Provision of a comprehensive spectrum of services, including highways, railways, pipelines, dams and hydroelectric power plants (HEPP), treatment plants, industrial complexes, commercial buildings, and hotel developments"
    },
    {
        TR: "Dünya çapında uzman mühendislerden oluşan, 20.000 kişilik küresel bir profesyonel iş gücü",
        EN: "A global workforce of 20,000 professionals, predominantly expert engineers"
    }
];
