import React, { useEffect, useState } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { API_URL } from '../../api/api'
import { motion } from 'framer-motion'
import { mobilVariant } from '../../components/animation'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJob } from '../../store/slices/jobSlice'
import { fetchJobs } from '../../store/slices/jobsSlice'

const JobPage = () => {
    const dispatch = useDispatch();
    const jobsResponse = useSelector((state) => state.jobs.jobs);
    const response = useSelector((state) => state.job.job);
    const [lang, setLang] = useState(localStorage.getItem("lang") || "EN");
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({});
    const [jobs, setJobs] = useState([]);
    const [displayText, setDisplayText] = useState('');

    const getData = async () => {
        try {
            if (response) {
                setData(response);
                const parsedData = lang === "TR" ? response.imageText : response.imageTextEN;
                setDisplayText(parsedData);
            } else {
                setDisplayText('No data available');
            }

            setJobs(jobsResponse);
            if (data !== undefined && data !== null) {
                setTimeout(() => {
                    setLoad(true);
                }, 300);
            }
        } catch (error) {
            console.error('API call error:', error);
            setDisplayText('Error fetching data');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchJob());
            await dispatch(fetchJobs());
        };
        fetchData();
    }, [lang]);

    useEffect(() => {
        getData();
    }, [response, jobsResponse, lang]);

    return (
        <motion.div {...mobilVariant}>
            <div className='job-page'>
                <Header />

                <div className='top'>
                    {data.fileFullPath && (
                        <img
                            src={`${API_URL}${data.fileFullPath}`}
                            alt="Job Page"
                            onError={(e) => {
                                e.target.src = 'path/to/default/image.jpg';
                                e.target.alt = 'Default Job Page Image';
                            }}
                            className="job-page-image"
                        />
                    )}
                    {/* <div className="triangle"></div> */}
                    <div className='text'>
                        <h3>{displayText}</h3>
                    </div>
                </div>

                <div className='jobs'>
                    <div><h2>{lang === "TR" ? data.title : data.titleEN}</h2></div>
                    <div id="jobs">
                        <div className="row title">
                            <div className="col-md-4">
                                {lang === "TR" ? "Pozisyon" : "Position"}
                            </div>
                            <div className="col-md-4">
                                {lang === "TR" ? "Kıdem" : "Seniority"}
                            </div>
                            <div className="col-md-3">
                                {lang === "TR" ? "Lokasyon" : "Location"}
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        {jobs.length > 0 ? (
                            jobs.map((item, index) => (
                                <div key={index} className="row job">
                                    <div className="col-md-4">{lang === "TR" ? item.position : item.positionEN}</div>
                                    <div className="col-md-4">{lang === "TR" ? item.seniority : item.seniorityEN}</div>
                                    <div className="col-md-3">{lang === "TR" ? item.location : item.locationEN}</div>
                                    <div className="col-md-1">
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="row job">
                                <div className="col-md-12">{lang === "TR" ? "İş ilanı bulunamadı" : "No jobs available"}</div>
                            </div>
                        )}
                    </div>
                </div>

                <Footer />
            </div>
        </motion.div>
    )
}

export default JobPage
